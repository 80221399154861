.app-container {
  margin: 0;
  padding: 1rem;
  padding-top: 0;
}

.history-items-container {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: space-around;
}

.year-block {
  border: 1px solid black;
  width: 14rem;
  margin-bottom: 2rem;
  padding: 0;
  background-color: white;
}

h1 {
  font-size: 6rem;
  margin: 0 0 1.6rem 1rem;
}

h2 {
  padding: 0;
  margin: 0;
  font-size: 2.6rem;
}

h4 {
  margin: 1rem;
  font-size: 1.2rem;
}

.year-banner {
  width: 100%;
  background-color: limegreen;
}
